/* Slider */
.slick-loading .slick-list {
    background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}

.slick-slider {
    margin: 0;
    padding: 0;
    min-height: 100px;
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    z-index: 10;

    display: block;

    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent no-repeat center center;

}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    opacity: 1;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    opacity: .25;
}


.slick-prev {
    background-image: url(../images/slider-left-arrow.svg);
    background-size: 16px auto;
    left: 0px;
}

[dir='rtl'] .slick-prev {
    right: 0px;
    left: auto;
}


.slick-next {
    background-image: url(../images/slider-right-arrow.svg);
    background-size: 16px auto;
    right: 0;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: 0;
}


/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .slick-prev {
        left: 25px;
        background-size: auto auto;
    }

    [dir='rtl'] .slick-prev {
        left: auto;
    }
    .slick-next {
        right: 25px;
        background-size: auto auto;
    }

    [dir='rtl'] .slick-next {
        left: 25px;
    }
}


/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}


.slick-dots {
    position: absolute;
    bottom: -55px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 45px;
    height: 45px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    overflow: hidden;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20px;
    height: 20px;
    content: '';
    opacity: .25;
    background: #000000;
    border-radius: 20px;
    transition: all $link-transition;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    background: #000000;
}
