// Sprout 2 checkbox tables

.checkbox-list {
    border: none;
    border-color: #F0F0F0;
    border-collapse: collapse;
    width: 100%;
}

.checkbox-list tr:first-child {
	border-top: none;
}

.checkbox-list tr:last-child {
	border-bottom: none;
}

.checkbox-list tr,
.checkbox-list tr:nth-child(even),
.checkbox-list tr:nth-child(odd) {
    background-color: transparent;
}

.checkbox-list td,
.checkbox-list th {
    padding: 2px;
}

.checkbox-list td.check {
    width: 20px;
}

.checkbox-list label {}

.event-form-check {
    display: inline-block;
    margin-right: 10px;
}

.event-form-checkdesc {
    display: inline-block;
}

.checkbox-list .check + .name p {
    margin-bottom: 0;
}


