/* fieldset styles */
fieldset {
   border: none;
   padding: 0;
   margin: 0;
}

.field-label,
.fieldset__legend {
   font-weight: 600;
   font-size: $font-regular;
   margin-bottom: $form-spacing;
   line-height: 1.2;
   font-family: $primary-font;
}

.field-element--checkboxlist--columns .field-element__input-set {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
}

/* Hide checkbox and radio field element) */
.field-element input[type="checkbox"],
.field-element input[type="radio"],
input.pseudo-anchor[type="checkbox"],
input.pseudo-anchor[type="radio"] {
    @include vis-hidden;
}

.field-element input[type="checkbox"] + label,
.field-element input[type="radio"] + label,
.field-element--small input[type="checkbox"] + label,
.field-element--small input[type="radio"] + label,
input[type="checkbox"] + .pseudo,
input[type="radio"] + .pseudo {
    padding-left: $form-spacing*4;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

input[type="checkbox"] + .pseudo,
input[type="radio"] + .pseudo {
    padding-left: 0;
}

/* -- Field set pseudo elements -- */
.field-element input[type="checkbox"] + label:before,
.field-element input[type="radio"] + label:before,
.field-element--small input[type="checkbox"] + label:before,
.field-element--small input[type="radio"] + label:before,
input[type="checkbox"] + label.pseudo:before,
input[type="radio"] + label.pseudo:before {
    content: " ";
    display: inline-block;
    width: $form-spacing*3;
    height: $form-spacing*3;
    margin-right: $form-spacing*1.5;
    position: relative;
    top: $form-spacing/2;
    background-color: $color-grey-01;
    border: 1px solid $color-grey-04;
    margin-left: -$form-spacing*4;
}

input[type="checkbox"] + label.pseudo:before,
input[type="radio"] + label.pseudo:before {
    margin-left: 0;
    margin-right: 0;
}

/* Radio button (pseudo element) */
.field-element input[type="radio"] + label:before,
.field-element--small input[type="radio"] + label:before,
input[type="radio"] + label.pseudo:before {
    border-radius: $form-spacing*1.5;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type="checkbox"]:checked + label:after,
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after,
input[type="checkbox"]:checked + label.pseudo:after,
input[type="radio"]:checked + label.pseudo:after {
    content: " ";
    position: absolute;
    left: 1px;
    top: $form-spacing*0.75;
}
/* Checkbox box checked (pseudo element) */
.field-element input[type="checkbox"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:checked + label.pseudo:after {
   content: "\00a0";
   background-image: url(../images/icon-system/icon_form_tick-md.svg);
   background-position: left top;
   background-repeat: no-repeat;
   background-size: 90%;
   width: $form-spacing*3;
   height: $form-spacing*3;
}

/* Radio dot (pseudo element) */
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after,
input[type="radio"]:checked + label.pseudo:after {
    left: $form-spacing - 1;
    top: $form-spacing *1.5 - 1;
    width: $form-spacing*1.2;
    height: $form-spacing*1.2;
    border-radius: $form-spacing*0.6;
    background: $color-grey-07;
}

/* White */
.field-element--white input[type="checkbox"] + label:before,
.field-element--white input[type="radio"] + label:before {
    background-color: $color-white;
}

/* input focus state */
.fieldset--multiradio:focus-within {
    outline: $color-grey-06 auto 5px;
}
.field-element input[type="checkbox"]:focus + label {
    outline: $color-grey-06 auto 5px;
    outline-offset: -3px;
}
