
/* ---- Placeholders ---- */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #a29999;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #a29999;
}

:-ms-input-placeholder { /* IE 10+ */
    color: #a29999;
}

:-moz-placeholder { /* Firefox 18- */
    color: #a29999;
}

.field-element--dropdown--placeholder .field-input select {
    color: #a29999;
}

 /* All form elements with preceeding icon */
 .field-element--datepicker .field-input .textbox,
 .field-element--daterangepicker .field-input .textbox,
 .field-element--datetimepicker .field-input .textbox,
 .field-element--datetimerangepicker .field-input .textbox,
 .field-element--money .field-input .textbox,
 .field-element--timepicker .field-input .textbox {
     padding-left: $form-spacing*6.5;
 }

 /* Clearfix all field-elements */
.field-element:before,
.field-element:after {
    content: " ";
    display: table;
}

.field-element:after {
    clear: both;
}

/* ---- form colour varients ---- */
/* -- default - grey -- */
.textbox,
input[type="text"],
.field-input input,
.field-input textarea,
.field-input select {
    border: 1px solid $color-grey-04;
    border-radius: $standard-radius;
    background-color: $color-grey-01;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
    outline: $color-grey-06 auto 5px;
}

/* -- white -- */
.field-element--white .field-input input,
.field-element--white .field-input textarea,
.field-element--white .field-input select {
    background-color: $color-white;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type="checkbox"] + label,
.fieldset-input.fieldset-input--disabled input[type="checkbox"] + label,
.field-element--disabled input[type="radio"] + label,
.fieldset-input.fieldset-input--disabled input[type="radio"] + label {
    cursor: not-allowed;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
    @include vis-hidden;
}

/* ---- Required ---- */
.field-label__required {
    text-indent: -9999px;
    line-height: 0;
    display: inline-block;
}

.field-label__required:after {
    content: "*";
    text-indent: 0;
    display: block;
    line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
    font-weight: 400;
    font-size: $font-small;
    margin-bottom: 1em;
}

.field-label .field-helper {
    margin-top: 0.3em;
    margin-bottom: 0;
}

/* ---- Field errors ---- */
.field-element.field-element--error  .field-input .textbox,
.field-element.field-element--error  .field-input select {
    background-color: lighten($color-utility-warning, 62%);
}
.field-error__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.field-error__list__item {
    color: $color-white;
    background: $color-utility-warning;
    padding: 10px 20px;
    margin: 11px 0;
    border-left: 6px solid $color-utility-warning-subtle;
}

/* ---- Attach wrapper ---- */
.field-element-attach-wrapper {
    position: relative;
}
.field-element-attach-wrapper__button {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: $color-primary;
    border: none;
    outline: none;
    padding: 0 7px;
    z-index: 1;
    cursor: pointer;
    min-width: 45px;
}
.field-element-attach-wrapper__button svg {
    vertical-align: middle;
}
.field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper__button:active,
.field-element-attach-wrapper__button:focus {
    background-color: $color-grey-04;
}
.field-element-attach-wrapper .field-element .field-input .textbox,
.field-element-attach-wrapper .field-element .field-input select {
    padding-right: 55px;
}
/* White attachment wrapper */
.field-element-attach-wrapper--white .field-element-attach-wrapper__button {
    background-color: $color-grey-02;
}
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:active,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:focus {
    background-color: $color-grey-03;
}

/* ---- form style extenders ---- */
%form-field-pseudo-anchor--before {
    position: absolute;
    top: -$form-spacing;
    left: $form-spacing*2;
    display: inline-block;
    border-right: $form-spacing solid transparent;
    border-bottom: $form-spacing solid $color-grey-04;
    border-left: $form-spacing solid transparent;
    border-bottom-color: $color-grey-04;
    content: '';
}
%form-field-pseudo-anchor--after {
    position: absolute;
    top: -$form-spacing + 1;
    left: $form-spacing * 2 + 1;
    display: inline-block;
    border-right: $form-spacing - 1 solid transparent;
    border-bottom: $form-spacing - 1 solid #fff;
    border-left: $form-spacing - 1 solid transparent;
    content: '';
}

form h1,
form h2,
form h3,
form h4,
form h5 {
    margin-top: 1em;
}
