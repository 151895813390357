.content-search-box input[type="text"] {
    width: calc(100% - 96px);
    display: inline-block;
}

.content-search-box input[type="submit"] {
    width: 88px;
    float: right;
}

.search-result h2 {
    font-size: 2rem;
}
