/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
    max-width: 1000px;
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    background-color: #fff;
    margin: 0 auto;
    padding: 50px;
    max-width: 800px;
}
