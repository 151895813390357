
/* ------------------------------------------------------
**** Legacy Sponsors Widget
------------------------------------------------------ */

.sponsor-category {
	margin-bottom: 15px;
}

.widget-Sponsors .sponsor-item {
	margin: 10px 1%;
	float: left;
	width: 23%;
	text-align: center;
	min-height: 180px;
	padding: 12px 8px 0;
	border: 1px solid #D5D4D4;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
	.cat-gold_sponsors .sponsor-item {
		width: 32%;
	}
	.cat-platinum_sponsors .sponsor-item {
		width: 32%;
	}
	.cat-silver_sponsors .sponsor-item {
		font-size: .9rem;
	}

.sponsor-item__img-wrap {
	display: block;
	height: 150px;
	line-height: 150px;
	margin-bottom: 15px;
}

 .sponsor-item__name {
 	min-height: 60px;
 }

.widget-Sponsors .sponsor-item:nth-child(4n+2) {
	margin-left: 0;
}
	.widget-Sponsors .sponsor-item:nth-child(4n+2) {
		margin-left: inherit;
	}
	.widget-Sponsors .cat-platinum_sponsors .sponsor-item:nth-child(3n+2) {
		margin-left: 0;
	}
	.widget-Sponsors .cat-gold_sponsors .sponsor-item:nth-child(3n+2) {
		margin-left: 0;
	}

.widget-Sponsors .sponsor-item:nth-child(4n+1) {
	margin-right: 0;
}
	.widget-Sponsors .sponsor-item:nth-child(4n+1) {
		margin-right: inherit;
	}
	.widget-Sponsors .cat-platinum_sponsors .sponsor-item:nth-child(3n+1) {
		margin-right: 0;
	}
	.widget-Sponsors .cat-gold_sponsors .sponsor-item:nth-child(3n+1) {
		margin-right: 0;
	}
.widget-Sponsors .sponsor-item a {
	text-decoration: none;
	display: block;
}
.widget-Sponsors .sponsor-item a:first-child {
	/* min-height: 100px; */
}
.widget-Sponsors .sponsor-item img {
	max-width: 100%;
	max-height: 100%;
	vertical-align: middle;
}
