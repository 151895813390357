#facebox .content {
	padding: 20px;
	background: none;
}

.popup .content {
    border-bottom: none;
    margin-bottom: $spacing*2;

    @media screen and (min-width: 75em) {
        grid-template-columns: auto;
        grid-gap: 0;
    }
}

.popup-content {
    &__title {
        padding-bottom: $spacing*2;
        margin-bottom: $spacing*4;
        border-bottom: 1px solid $color-grey-02;
    }
}
