
/* ---- Blog hub ---- */
.blog-post-preview {
	margin: 0 0 20px;
}
.blog-post-preview__inner {
	border: 1px solid #dedede;
	padding: 20px 20px 16px;
	-webkit-transition: background 300ms ease;
	-o-transition: background 300ms ease;
	transition: background 300ms ease;
	height: 100%;
	overflow: hidden;
}
.blog-post-preview .blog-post-featured-img-small {
	width: 100%;
}
.blog-post-preview__inner:hover {
	background: #f8f8f8;
}
.blog-post-preview__inner:hover .view-more-link {
	opacity:0.9;
}
.blog-post-preview .view-more-link {
	padding: 20px;
	border: 1px solid #dedede;
}
.blog-post-preview div.side {
	float: left;
	width: 19.7%;
	border-right: 1px solid #dedede;
	text-align: center;
	margin-top:  30px;
}
.blog-post-preview .blog-date {
	border-bottom: 1px solid #dedede;
	float: right;
	padding: 0 11% 0 0;
	margin-bottom: 20px;
}
.blog-post-preview div.share {
	padding-right: 14%;
	margin-top: 10px;
}
.blog-post-preview div.hub-share p {
	color: #909396;
	text-transform: uppercase;
	font-size: 1.5rem;
	padding: 0;
	margin: 0;
	display: inline;
}
.blog-post-preview div.hub-share a img {
	margin: 0 0 0 15px;
	display: inline;
}
div.share a:hover {
	text-decoration: none;
}
div.share a:hover img {
	zoom: 1;
	opacity: .5;
	-webkit-transition: opacity 300ms ease-in;
	-o-transition: opacity 300ms ease-in;
	transition: opacity 300ms ease-in;
}
.blog-post-preview a {
	text-decoration: none;
}
.blog-post-preview div.excerpt {
	margin-top: 20px;
}
.blog-post-preview div.excerpt h3 {
	font-weight: 300;
	font-size: 2.1rem;
	text-align: left;
	padding-bottom: 0;
	margin: 0;
}
.blog-post-preview div.excerpt h3:before,
.blog-post-preview div.excerpt h3:after {
	display: none;
}
.blog-post-preview div.excerpt p.author {
	font-size: 1.4rem;
	text-transform: uppercase;
}
.blog-post-preview div.excerpt p {
	line-height: 140%;
	font-size: 1.7rem;
	margin: 12px 0 0;
}


.bloghub-posts--unfiltered .blog-post-preview--hidden,
.bloghub-posts--filtered .blog-post-preview--filtered-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.bloghub-posts--unfiltered .blog-post-preview--animate {
	-webkit-animation: fadeInDown 300ms ease;
	        animation: fadeInDown 300ms ease;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.bloghub-posts--filtered .blog-post-preview--filtered-pre-visible {
	opacity: 0;
}

.bloghub-posts--unfiltered .blog-post-preview--animate-grow,
.bloghub-posts--filtered .blog-post-preview--filtered-visible {
	-webkit-animation: fadeInDown 350ms ease;
	        animation: fadeInDown 350ms ease;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.blog-post-preview--image-loading .blog-post-featured-img-small {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.blog-post-preview .blog-post-img-link {
	position: relative;
	overflow: hidden;
	padding-top: 61.67%;
	display: block;
}
.blog-post-preview .blog-post-featured-img-small,
.blog-post-preview .blog-post-featured-img-small-placeholder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
}

.blog-post-preview .blog-post-featured-img-small-placeholder {
	-webkit-filter: blur(6px);
	        filter: blur(6px);
	-webkit-transform: scale(1.05);
	    -ms-transform: scale(1.05);
	        transform: scale(1.05);
	-webkit-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	z-index: 1;
}
.blog-post-preview.blog-post-preview--image-loaded .blog-post-featured-img-small-placeholder {
	opacity: 0;
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
	-webkit-transition-duration: 1.5s;
	     -o-transition-duration: 1.5s;
	        transition-duration: 1.5s;
}

.bloghub-filters {
	display: none;
}
.bloghub-filters.bloghub-filters--visible {
	display: block;
}
.bloghub-filters-list .button:focus {
	outline: none;
}

/* BP Small */
@media only screen and (min-width: 48.063em) { /* 769px */
	.bloghub-filters {
		display: block;
	}
	.bloghub-show-filters-button-wrap {
		display: none;
	}

	#bloghub-posts {
		margin-top: 20px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin-left: -1.5%;
		margin-right: -1.5%;
	}
	.blog-post-preview {
		padding: 0 1.5%;
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 50%;
		        flex: 0 0 50%;
		margin: 0 0 35px;
	}
	.blog-post-preview__inner {
		padding: 30px;
	}
	.blog-post-preview div.excerpt h3 {
		font-size: 2.2rem;
	}
}

@media screen and (min-width: 1000px) {
	.blog-post-preview {
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 33.33333%;
				flex: 0 0 33.33333%;
		max-width: 33.33%;
	}
	.blog-post-preview div.excerpt h3 {
		font-size: 2.5rem;
	}
}


/* ---- Blog list items ---- */

.blog-item {
	margin-bottom: 24px;
	padding-bottom: 10px;
	border-bottom: 2px solid #e4e5e5;
}

.details {
	text-transform: uppercase;

	display: flex;
	flex-flow: row nowrap;
}

.details .author {
	padding-right: 6px;
}



/* ---- Blog Posts ---- */

.blog-posts {
	margin: 30px 0;
}

.blog-post-featured-img {
	margin-bottom: 16px;
}

.blog-post {
	border-bottom: 2px solid #e4e5e5;
	padding-bottom: 5px;
	margin-bottom: 25px;
}

.blog-post__details {
	display: block;
	font-size: 1.6rem;
	text-transform: uppercase;
	line-height: 1.6;

	display: flex;
	flex-flow: row nowrap;
}

.blog-post__details .author {
	padding-right: 6px;
}

.blog-post__img {
	margin-bottom: 15px;
}

.blog-posts__footer-link {
	margin: -10px 0 0;
	padding-bottom: 15px;
	border-bottom: 2px solid #e4e5e5;
}

.widget-BlogIntro.orientation-tall .blog-post__img {
	display: none;
}

.blog-post__body {
	padding-bottom: 40px;
}

.blog-post__tags {
	border-top: 2px solid #e4e5e5;
	clear: both;
	padding: 10px 0;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.blog-post__tags h4 {
	padding-right: 16px;
	font-family: "proxima-nova",Helvetica,Arial,sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	margin: 0;
	text-transform: uppercase;
}

.blog-post__tags .button {
	margin: 8px;
}

.blog-post__footer {
	border-top: 2px solid #e4e5e5;
	padding: 12px 0 8px;
}

.blog-post__footer .share {
	text-transform: uppercase;
}

.blog-post__footer .share p {
	margin: 0;
	display: flex;
	flex-flow: row nowrap;
	align-content: center;
}

.blog-post__footer .share a {
	display: inline-block;
	text-decoration: none;
	margin-left: 12px;
	display: flex;
}

.blog-post__footer .share a.share-btn--facebook {
	margin-left: 4px;
}



@media screen and (min-width: 37.5em) {/*  600px */

    .blog-post__footer .share {
    	display: -webkit-box;
    	display: -ms-flexbox;
    	display: flex;
    }
	.blog-post__footer .share:last-child {
		display: flex;
		justify-content: flex-end;
	}

}

@media screen and (min-width: 600px) and (max-width: 799px) {

	.blog-post__img {
		width: 28%;
		float: left;
	}
	.blog-post__content {
		float: right;
		width: 68%;
	}

	.widget-BlogIntro.orientation-tall .blog-post__content {
		width: 66%;
		float: left;
		margin-left: 20px;
		margin-top: 20px;
	}

	.widget-BlogIntro.orientation-tall .blog-post__img {
		display: block;
		max-width: 100px;
	}
}

@media screen and (min-width: 1000px) {
	.blog-post__img {
		width: 28%;
		float: left;
	}
	.blog-post__content {
		float: right;
		width: 68%;
	}

	.widget-BlogIntro.orientation-tall .blog-post__content {
		width: 100%;
	}
}
@media screen and (min-width: 1200px) {
	.widget-BlogIntro.orientation-tall .blog-post__content {
		float: right;
		width: 66%;
	}
	.widget-BlogIntro.orientation-tall .blog-post__img {
		display: block;
		max-width: 100px;
	}
}
