
ul.messages {
    list-style: none;
    margin: 16px 0 25px;
    padding: 0;
    border: 0px;
    background: none;
}

ul.messages li {
    margin: 0 0 15px;
    padding: 10px 20px;
    vertical-align: bottom;
    text-align: left;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
}

ul.messages .error {
    background-image: none !important;
    background-color: $color-utility-warning-subtle !important;
    border-left: 4px solid $color-utility-warning;
    color: #FFF !important;
}

ul.messages .confirm {
    color: #FFF !important;
    background: $color-utility-positive-subtle !important;
    border-left: 4px solid $color-utility-positive;
}

ul.messages .warning {
    color: #FFF !important;
    background: $color-utility-alert-subtle !important;
    border-left: 4px solid $color-utility-alert;
}

ul.messages a {
    color: #FFF;
}

span.field-error {
    background: none;
    color: #fff !important;
    background-color: $color-utility-warning-subtle !important;
    border-left: 4px solid $color-utility-warning;
    font-size: 1.4rem;
    border-right: none;
    padding: 8px 10px 8px 10px;
    display: inline-block;
}

.error span.field-error {
    background: none;
    color: #fff;
    font-weight: bold;
    border-right: none;
    padding: 0;
    margin: 0px;
    font-size: 1.5rem;
    vertical-align: middle;
}
