
/* ---- Stacked tables ---- */
@media screen and (max-width: 767px) {
    .table--stack,
    .form-section {
        border-bottom: none;
        border: none;
    }
    .table--stack th,
    .form-section th {
        display: none;
    }
    .table--stack,
    .table--stack thead,
    .table--stack tbody,
    .table--stack tr,
    .table--stack td,
    .form-section,
    .form-section thead,
    .form-section tbody,
    .form-section tr,
    .form-section td {
        display: block;
        width: 100%;
    }
    .table--stack thead {
        display: none;
    }
    .table--stack tr,
    .table--stack.table--content-standard tr,
    .form-section tr {
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .table--stack tr td:first-child,
    .form-section tr td:first-child {
        border-top: none;
    }
    .table--stack td,
    .form-section td {
        border: none;
    }
    .table--stack td:empty,
    .form-section td:empty {
        display: none;
    }
    .table--stack td[data-heading]:before,
    .form-section td[data-heading]:before {
        content: attr(data-heading);
        display: block;
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        font-weight: bold;
    }

    .table--stack.table--stack--horizontal th,
    .form-section th {
        display: block;
    }
}
