
.media-object {
    padding: 20px 0;
    width: 100%;
    float: left;
}

:where(a).media-object {
    text-decoration: none;
}

.media-object:after,
.media-object:before {
    clear: both;
}

.media-object__img {
    float: left;
    width: 100%;
    height: 100%;

    .media-object--date & {
        width: 72px;
    }
}

.media-object__img img {
    width: 100%;
}

.media-object__content {
    width: 100%;
    float: left;
    margin-top: 20px;

    .media-object--date & {
        width: calc(100% - 72px);
        padding-left: $spacing*2;
        margin-top: 0;
    }
}

.media-object__heading {
    color: $color-primary;
}

// Used in Date Card
.media-object__content__textarea {
    margin-top: $spacing;
}

.media-object__content__textarea p {
    margin: 0;
    display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.media-object__details {
    font-weight: bold;
    color: $color-grey-05;
}

.media-object__date:after {
    content: " | ";
}

@media screen and (min-width: 48em) { /* 768px */
    .media-object {
        padding: 0;
    }
    .media-object__img {
        width: 33%;

        .media-object--date & {
            width: 70px;
        }
    }

    .media-object__content {
        width: 66%;
        margin-top: 0;
        padding-left: 20px;

        .media-object--date & {
            width: calc(100% - 72px);
        }
    }
}
