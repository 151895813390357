
/* Arrows */
.card-slider .slick-arrow {
    top: 23vw;
}
/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */
    .card-slider .slick-arrow {
        top: 30%;
    }
}
