
table {
    border-collapse: collapse;
    border: 1px solid #F0F0F0;
    width: 100%;
	border-collapse: collapse;
	margin: 8px 0 14px;
}

tr:first-child {
	border-top: 1px solid #F0F0F0;
}

tr:last-child {
	border-bottom: 1px solid #F0F0F0;
}

tr:nth-child(odd) {
    background-color:#FFF;
}

tr:nth-child(even) {
    background-color:#F0F0F0;
}



th,
td {
    padding: 5px 10px;
    border: none;
    background: transparent;
}

th {
	background-color:#F0F0F0;
}

thead td {
    background: transparent;
}

table .button {
    word-break: inherit;
}
