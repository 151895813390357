
/* ------------------------------------------------------
**** ATOMS
------------------------------------------------------ */

/* ---- Global Variables ---- */

@import "06-abstracts/colors";

@import "06-abstracts/fonts";

@import "06-abstracts/spacing";

@import "06-abstracts/breakpoints";

@import "06-abstracts/animation";



/* ---- Base HTML ---- */


@import "../../sasta_v3/_patterns/00-base/_base/viewport";

@import "../../sasta_v3/_patterns/00-base/_base/box-model-reset";

@import "../../sasta_v3/_patterns/00-base/_base/page";

@import "../../sasta_v3/_patterns/00-base/_base/font-face";

@import "../../sasta_v3/_patterns/00-base/_flexboxgrid-extensions/_flexboxgrid-extensions";

@import "../../sasta_v3/_patterns/00-base/images/img";

@import "../../sasta_v3/_patterns/00-base/container/container";

@import "../../sasta_v3/_patterns/00-base/wrap/wrap";

@import "../../sasta_v3/_patterns/00-base/section/background-colours";

@import "../../sasta_v3/_patterns/00-base/section/section";

@import "../../sasta_v3/_patterns/00-base/block/block";

@import "../../sasta_v3/_patterns/00-base/box/box";

@import "../../sasta_v3/_patterns/00-base/content-align/content-align";

@import "../../sasta_v3/_patterns/00-base/floats/floats";




/* ---- Text ---- */

@import "../../sasta_v3/_patterns/00-base/typography/headings";

@import "../../sasta_v3/_patterns/00-base/typography/paragraph";

@import "../../sasta_v3/_patterns/00-base/typography/inline-elements";

@import "../../sasta_v3/_patterns/00-base/typography/hr";

@import "../../sasta_v3/_patterns/00-base/typography/lists/lists";

@import "../../sasta_v3/_patterns/00-base/typography/lists/inline-list";



/* ---- Buttons and links ---- */



@import "../../sasta_v3/_patterns/00-base/buttons-and-links/text-link";

@import "../../sasta_v3/_patterns/00-base/buttons-and-links/button";





/* ---- Images and Icons ---- */


@import "../../sasta_v3/_patterns/00-base/icons/icons";




/* ---- Video ---- */


@import "../../sasta_v3/_patterns/00-base/video-embed";



/* ---- Tables ---- */


@import "../../sasta_v3/_patterns/00-base/tables/table";

@import "../../sasta_v3/_patterns/00-base/tables/table-stacked";

@import "../../sasta_v3/_patterns/00-base/tables/content-table/content-table";

@import "../../sasta_v3/_patterns/00-base/tables/small-content-table/small-content-table";

@import "../../sasta_v3/_patterns/00-base/tables/responsive-table/responsive-table";






/* ---- Helpers ---- */


@import "../../sasta_v3/_patterns/00-base/helpers/clearfix";

@import "../../sasta_v3/_patterns/00-base/helpers/img-replace";

@import "../../sasta_v3/_patterns/00-base/helpers/hidden/hidden";

@import "../../sasta_v3/_patterns/00-base/helpers/vis-hidden/vis-hidden";

@import "../../sasta_v3/_patterns/00-base/helpers/invisible/invisible";

@import "../../sasta_v3/_patterns/00-base/helpers/_pseudo-element-content";

@import "../../sasta_v3/_patterns/00-base/helpers/_quick-spacing";

@import "../../sasta_v3/_patterns/00-base/helpers/text-helpers";



/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */

/* ---- Text ---- */

@import "../../sasta_v3/_patterns/01-components/text-blocks/blockquote";

@import "../../sasta_v3/_patterns/01-components/text-blocks/highlight";

@import "../../sasta_v3/_patterns/01-components/text-blocks/expando";

@import "../../sasta_v3/_patterns/01-components/text-blocks/site-messages";

@import "../../sasta_v3/_patterns/01-components/text-blocks/tooltip";



/* ---- Lists ---- */


@import "../../sasta_v3/_patterns/01-components/lists/sidebar-linklist";

@import "../../sasta_v3/_patterns/01-components/lists/social-list";

@import "../../sasta_v3/_patterns/01-components/lists/children-pg-list/children-pg-list";

@import "../../sasta_v3/_patterns/01-components/lists/file-list";

@import "../../oliphant_2020/_patterns/01-components/lists/file-list";

@import "../../sasta_v3/_patterns/01-components/lists/block-list/block-list";



/* ---- Navigation ---- */


@import "../../sasta_v3/_patterns/01-components/navigation/breadcrumb";

@import "../../sasta_v3/_patterns/01-components/navigation/pagination";

@import "../../oliphant_2020/_patterns/01-components/navigation/pagination";

@import "../../sasta_v3/_patterns/01-components/navigation/related-links";

@import "01-components/navigation/primary-nav/_primary-nav";



/* ---- Plugins / third-party styles ---- */

@import "../../sasta_v3/_patterns/01-components/magnific-popup/magnific-popup";

@import "../../sasta_v3/_patterns/01-components/facebox-popup/facebox-popup";



/* ---- Blocks ---- */



@import "01-components/btn-filter/btn-filter";

@import "01-components/date-card/date-card";

@import "01-components/tile/tile";

@import "01-components/banner-basic/banner-basic";

@import "01-components/image-galleries/image-gallery";

@import "01-components/media-objects/media-object";

@import "01-components/media-objects/card";

@import "01-components/image-slider/slick-slider/image-slider";

@import "01-components/image-slider/slick-slider/image-slider-theme";

@import "01-components/image-slider/sans-slick/image-slider-clean";

@import "01-components/card-slider/card-slider-theme";





/* ---- Forms ---- */


@import "../../sasta_v3/_patterns/01-components/forms/form-base";

@import "../../sasta_v3/_patterns/01-components/forms/form-sections";

@import "../../sasta_v3/_patterns/01-components/forms/color-picker";

@import "../../sasta_v3/_patterns/01-components/forms/date-input";

@import "../../sasta_v3/_patterns/01-components/forms/date-picker";

@import "../../sasta_v3/_patterns/01-components/forms/date-time-input";

@import "../../sasta_v3/_patterns/01-components/forms/fieldsets";

@import "../../sasta_v3/_patterns/01-components/forms/table-checkboxes";

@import "../../sasta_v3/_patterns/01-components/forms/money";

@import "../../sasta_v3/_patterns/01-components/forms/range";

@import "../../sasta_v3/_patterns/01-components/forms/select";

@import "../../sasta_v3/_patterns/01-components/forms/range";

@import "../../sasta_v3/_patterns/01-components/forms/text-field";

@import "../../sasta_v3/_patterns/01-components/forms/time-pickers";

@import "../../sasta_v3/_patterns/01-components/forms/total-selector";

@import "../../sasta_v3/_patterns/01-components/forms/upload";

@import "../../sasta_v3/_patterns/01-components/forms/submit-bar";

@import "../../sasta_v3/_patterns/01-components/forms/site-search";

@import "../../sasta_v3/_patterns/01-components/forms/captcha";

@import "../../sasta_v3/_patterns/01-components/forms/upload/upload--inline";

@import "../../sasta_v3/_patterns/01-components/forms/enews-form";

@import "../../oliphant_2020/_patterns/01-components/search-form/_search-form";


/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */

/* ---- Global ---- */

@import "02-layout/header/header";

@import "02-layout/footer/footer";

@import "02-layout/lists/tile-list";

@import "02-layout/lists/top-heavy-list";

@import "02-layout/lists/media-object_list";

@import "02-layout/lists/card_list";





/* ------------------------------------------------------
**** MODULES
------------------------------------------------------ */

/*
 * NOTE: [modules:01]
 * DO NOT PUT core module css here
 * the following are for bespoke skin styles that are ADDITIONAL to core module css
 */

 @import "../../sasta_v3/_patterns/01-components/lists/product-list/_product-list";

 @import "../../sasta_v3/_patterns/01-components/lists/product-listing/_product-listing";

 @import "../../sasta_v3/_patterns/03-modules/shopping-cart/shopping-cart";

 @import "../../oliphant_2020/_patterns/03-modules/sponsors/_legacy_sponsor_list";

 @import "../../oliphant_2020/_patterns/03-modules/blogposts/_blogposts-legacy";

 @import "../../sasta_v3/_patterns/03-modules/blog/blog";



/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */

@import "04-pages/home/home";

@import "04-pages/inner/inner";



/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /* Don't show links for images, or javascript/internal links */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
