.submit-bar {
    text-align: right;
    background: $color-grey-02;
    padding: $form-spacing*2;
    margin: $form-spacing*2 0;
}

.sidebar .submit-bar {
    padding: $form-spacing*1.5 0;
}

.submit-bar a {
    margin-right: $form-spacing*2.5;
}


// sticky submit bar

.submit-bar--sticky {
    position: fixed;
    z-index: 9000;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
    padding-bottom: 40px;
    -webkit-backface-visibility: hidden;
    transition: all $link-transition;

    &.scrolled-down {
        padding-bottom: 40px;
    }
    &.scrolled-up {
        padding-bottom: 20px;
    }
    @media screen and (min-width: 62em) { /* 992px */

        &.scrolled-down {
            padding-bottom: 20px;
        }
    }

    @media only screen and (min-width: 75em) { /* 1200px */
        .container {
            padding-left: 5em;
            padding-right: 5em;

        }
    }


}


