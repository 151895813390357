
.container {
    width: calc(100% - 1em);
    max-width: 1460px;
    margin: 0 auto;


    @media screen and (min-width: 22.5em) {/* 360px */
        width: calc(100% - 2em);
    }

    @media screen and (min-width: 30em) {/* 480px */
        width: calc(100% - 3em);
    }

    /* BP Smaller */
    @media screen and (min-width: 35em) { /* 560px */

    }

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */
        max-width: calc(48em - 3em)
    }

    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */
        max-width: calc(62em - 3em)
    }

    /* BP large */
    @media screen and (min-width: 75em) { /* 1200px */
        max-width: calc(75em - 5em)
    }

    /* BP xlarge */
    @media screen and (min-width: 92em) {/* 1472px */
        max-width: calc(92em - 5em)
    }

}

