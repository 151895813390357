
.bg-white {
    background-color: $color-white;
}

.bg-lightgrey {
    background-color: $color-grey-02;
}

.bg-primary {
    background-color: $color-primary;
}

.bg-secondary {
    background-color: $color-secondary;
}

.bg-alternative {
    background-color: $color-alternative;
}

.bg-accent-01 {
    background-color: $color-accent-01;
}

.bg-accent-02 {
    background-color: $color-accent-02;
}

.reverse-text {
    color: #ffffff;
}

.reverse-text a {
    color: inherit;
}

.reverse-text a:hover,
.reverse-text a:focus,
.reverse-text a:active {
    color: inherit;
    opacity: .75;
}

@mixin reverse-text {
    color: #ffffff;

    & a {
        color: inherit;
    }

    & a:hover,
    & a:focus,
    & a:active {
        color: inherit;
        opacity: .75;
    }
}

