.product .qty input.textbox {
    max-width: 76px;
    display: inline;
}

.btn-remove {
    background: url(../images/icon-system/icon_form_cross-red.svg) no-repeat center center;
    background-size: 26px auto;
    width: 32px;
    height: 32px;
    margin-top: 1px;
    text-indent: -9999px;
    display: inline-block;
    opacity: .7;
    filter: grayscale(1);
    vertical-align: middle;
}


.btn-remove:hover,
.btn-remove:active {
    opacity: 1;
    filter: grayscale(0);
}

/* Cart buttons */
.cart-buttons {
	padding: 12px;
	margin: 30px 0;
    border: 1px solid #ddd;

    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;

    @media screen and (min-width: 48em) {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

.forward-link {
	margin-right: 5px;
}

/* BP 3 */
@media screen and (max-width: 600px) {
	.cart-buttons .button:last-child {
		margin-bottom: 0;
	}
	.cart-buttons {
		margin: 15px 0;
	}
}

.checkout-table .total {
    text-align: right;
}



/* Infoboxes */
.checkout-infobox {
	padding: 10px;
    margin: 16px 0;
    font-size: 1.4rem;
    color: #FFF;
    background-color: $color-utility-positive-subtle;
    border-left: 4px solid $color-utility-positive;
}

.checkout-errorbox {
    background-image: none !important;
    background-color: $color-utility-warning-subtle !important;
    border-left: 4px solid $color-utility-warning;
    color: #FFF !important;
}

.checkout-infobox a {
    color: inherit;
}

.checkout-infobox p {
    margin: 0 0 3px;
}

.checkout-infobox p:last-child {
    margin-bottom: 0;
}

.popup-message {
	background-color: #D2DDE9;
	margin: 0 0 15px 0;
	padding: 8px 10px;
	opacity: 0;
	color: #1E5792;
}
.popup-message:empty {
	display: none;
}


/* Shipping methods table */
#shipping-methods table {
	border-top: 1px #e7e7e7 dashed;
	border-bottom: 1px #e7e7e7 dashed;
	width: 100%;
	margin-bottom: 20px;
}

	#shipping-methods table td.name {
		font-size: 14px;
		width: 320px;
	}

	#shipping-methods table td.info {
		font-size: 10px;
		width: 300px;
		padding: 5px 0;
	}

	#shipping-methods table td.total {
		font-size: 14px;
		text-align: right;
		color: #333;
	}


/* Payment module stuff */
#payment-listing {
	margin-bottom: 20px;
}

table.payment-methods {
	width: 100%;
	margin-bottom: 15px;
	border-collapse: collapse;
}

table.payment-methods td.radio {
    padding: 10px;
    border-bottom: 1px #ccc dashed;
    width: 35px;
}

table.payment-methods td.name {
    padding: 10px 0;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px #ccc dashed;
}

table.payment-methods td.desc {
    padding: 8px 0 15px 0;
}

div.payment-continue {
	text-align: right;
}

input.cc-cardname {
	width: 200px;
}

input.cc-cardnum {
	width: 200px;
}

select.cc-type {
	width: 120px;
}

select.cc-exp {
	width: 80px;
}

input.cc-cvn {
	width: 60px;
}


/* Confirmation */
table#payment-confirmation {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 30px;
}

	table#payment-confirmation th,
	table#payment-confirmation td {
		border-bottom: 1px #ccc dashed;
		padding: 8px 12px;
		font-size: 14px;
		text-align: left;
	}

	table#payment-confirmation th {
		font-weight: bold;
		width: 200px;
	}


@media print {
	#payment-steps { display: none; }
	div.checkout-infobox { display: none; }
	div.cart-buttons { display: none; }
}


/* SHOPPING CART */
.widget-ProductList table {
	width: 100%;
}



// popup add to cart form

.cart-add-form {
	display: grid;
	gap: $spacing*2;

	@include screen($bp560) {
		grid-template-columns: 1fr 1.75fr;
		gap: $spacing*3;
	}

	&__image {
		grid-row: 1 / -1;
		display: grid;
		justify-content: center;
	}

	&__product-name {
		margin-block: 0 $spacing;
		line-height: 1.25;
		text-wrap: balance;
	}

	&__price {
		font-size: 1.25em;
	}

	&__details {
		display: flex;
		flex-flow: column nowrap;
	}

	&__quantity {
		&__input {
			min-width: 45%;
			text-align: center;
			margin-top: 2px;
		}
	}

	&__btn-bar {
		display: flex;
		gap: $spacing;
		grid-column: 2 / -1;
		margin-top: auto;
	}

	:where(.button) {
		padding-block: $spacing*1.5;
	}

	&__add-t-cart {
		flex: 1 1 auto;
	}
}
