
/* Container Appearance */
.daterangepicker {
    position: absolute;
    background: #fff;
    top: 100px;
    left: 20px;
    padding: 4px;
    margin-top: 2px;
    border-radius: $standard-radius;
    width: 278px;
}

.daterangepicker.opensleft:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $color-grey-04;
    border-left: 7px solid transparent;
    border-bottom-color: $color-grey-04;
    content: '';
}

.daterangepicker.opensleft:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $color-white;
    border-left: 6px solid transparent;
    content: '';
}

.daterangepicker.openscenter:before {
    position: absolute;
    top: -7px;
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $color-grey-04;
    border-left: 7px solid transparent;
    border-bottom-color: $color-grey-04;
    content: '';
}

.daterangepicker.openscenter:after {
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $color-white;
    border-left: 6px solid transparent;
    content: '';
}

.daterangepicker.opensright:before {
    @extend %form-field-pseudo-anchor--before;
}

.daterangepicker.opensright:after {
    @extend %form-field-pseudo-anchor--after;
}

.daterangepicker.dropup{
    margin-top: -5px;
}

.daterangepicker.dropup:before{
    top: initial;
    bottom:-7px;
    border-bottom: initial;
    border-top: 7px solid $color-grey-04;
}

.daterangepicker.dropup:after{
    top: initial;
    bottom:-6px;
    border-bottom: initial;
    border-top: 6px solid $color-white;
}

.daterangepicker.dropdown-menu {
    max-width: none;
    z-index: 3000;
    display: none;
    border: 1px solid $color-grey-04;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
    float: none;
}

.daterangepicker .calendar {
    display: none;
    max-width: 270px;
    margin: 4px;
}

.daterangepicker.show-calendar .calendar {
    display: block;
}

.daterangepicker .calendar.single .calendar-table {
    border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
    white-space: nowrap;
    text-align: center;
    min-width: 32px;
    border: 1px solid $color-grey-02;
}

.daterangepicker .calendar-table {
    border: 1px solid $color-grey-03;
    padding: 4px;
    border-radius: $standard-radius;
    background: $color-white;
}

.daterangepicker table {
    width: 100%;
    margin: 0;
    border: none;
}

.daterangepicker td,
.daterangepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    white-space: nowrap;
    padding: 2px;
}
.daterangepicker td,
.daterangepicker th.available {
    cursor: pointer;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
    color: $color-grey-06;
    background: $color-grey-01;
    border-color: $color-grey-02;
}
.daterangepicker td.off.in-range {
    background-color: $color-grey-01;
}
.daterangepicker td.off.active {
    background-color: $color-grey-05;
    color: $color-white;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
    color: $color-grey-06;
    cursor: not-allowed;
    text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background: lighten($color-secondary, 25%);
}

.daterangepicker td.in-range {
    background: lighten($color-secondary, 30%);
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $color-secondary;
    color: $color-white;
}

.daterangepicker td.week, .daterangepicker th.week {
    font-size: 80%;
    color: $color-grey-04;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}

.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
    display: none;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
    margin-bottom: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 62px;
    background-color: $color-white;
    outline: none;
    border-radius: $standard-radius;
    font-size: 1.4rem;
    line-height: 1.2;
    padding: 5px 23px 5px 8px;
    border: 1px solid $color-grey-04;
    background-image: url(../images/icon-system/icon_form_arrow-down.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
}

.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 40%;
}

.daterangepicker select.yearselect {
    width: 44%;
}

.daterangepicker th.month {
    width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev,
.daterangepicker th.next {
    padding: 1px 2px;

}
.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
    width: $spacing*1.5;
    height: $spacing--medium;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    display: block;
    margin: 0 auto;
}
.daterangepicker th.next .daterangepicker-next-icon {
    background-image: url(../images/icon-system/icon_form_arrow-right.svg);
}
.daterangepicker th.prev .daterangepicker-prev-icon {
    background-image: url(../images/icon-system/icon_form_arrow-left.svg);
}
.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
    border: none;
    background: none;
}
.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
    background: none;
    opacity: 0.8;
}
.daterangepicker .calendar-table thead tr:nth-child(2) th {
    background-color: $color-grey-01;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
    border: 1px solid $color-grey-04;
    border-radius: $standard-radius;
    color: $color-grey-07;
    display: block;
    height: $spacing--xlarge;
    line-height: $spacing--xlarge;
    vertical-align: middle;
    margin: 0 0 $spacing--xsmall 0;
    padding: 0 $spacing--medium;
    width: 100%;
}

.daterangepicker .input-mini.active {
    border: 1px solid $color-accent-01;
    background-color: $color-grey-01;
}
.daterangepicker .input-mini:focus {
    outline: 4px auto $color-accent-01;
    outline-offset: -2px;
}

.daterangepicker .daterangepicker_input i {
    position: absolute;
    left: 8px;
    top: 7px;
    color: $color-grey-07;
}

.daterangepicker .daterangepicker_input {
    position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
    text-align: center;
    margin: 5px auto;
    line-height: 30px;
    position: relative;
}

.daterangepicker .calendar-time select.disabled {
    color: $color-grey-04;
    cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
    font-size: 11px;
    float: none;
    margin: 4px;
    text-align: left;
}

.daterangepicker .applyBtn {
    background-color: $color-accent-01;
    color: $color-white;
}
.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
    background-color: darken($color-accent-01, 10%);
    color: $color-white;
}

.daterangepicker .cancelBtn {
    background-color: $color-grey-03;
    color: $color-grey-09;
}
.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
    background-color: darken($color-grey-03, 10%);;
    color: $color-grey-09;
}

.daterangepicker .ranges ul {
    list-style: none;
    margin: 0 auto 12px;
    padding: 0;
    width: 100%;
}

.daterangepicker .ranges li {
    font-size: 1.3rem;
    background: $color-grey-02;
    border: 1px solid $color-grey-01;
    color: $color-grey-09;
    padding: 4px 12px 3px;
    margin-bottom: 7px;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
    background: $color-secondary;
    border: 1px solid $color-secondary;
    color: $color-white;
}

@supports(display: grid){
    .range_inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 8px;
    }
}

/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */
    .daterangepicker{
        width: auto;
    }

    .daterangepicker .ranges ul {
        width: 160px;
    }

    .daterangepicker.single .ranges ul {
        width: 100%;
    }

    .daterangepicker .calendar.left .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .daterangepicker .calendar.right .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .daterangepicker .calendar.left {
        clear: left;
        margin-right: 0;
    }

    .daterangepicker.single .calendar.left {
        clear: none;
    }

    .daterangepicker.single .ranges,
    .daterangepicker.single .calendar{
        float:left;
    }

    .daterangepicker .calendar.right {
        margin-left: 0;
    }

    .daterangepicker .left .daterangepicker_input {
        padding-right: 12px;
    }

    .daterangepicker .calendar.left .calendar-table {
        padding-right: 12px;
    }

    .daterangepicker .ranges,
    .daterangepicker .calendar {
        float: left;
    }

}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .daterangepicker .ranges {
        width: auto;
        float: left;
    }

    .daterangepicker .calendar.left {
        clear: none;
    }
}
