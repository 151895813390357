.breadcrumb {
    list-style: none;
    margin: $spacing 0 $spacing*2;
    padding: 0;
    font-size: 1.5rem;
    color: rgba($color-white, .8);

    @media screen and (min-width: 22.5em) {/* 360px */
        margin: $spacing 0 $spacing*3;
    }

    a {
        border-bottom: 1px solid rgba($color-white, 0);
        color: rgba($color-white, 1);
        text-decoration: none;
        margin-right: 20px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 4px;
            bottom: 4px;
            right: -12px;
            width: 1px;
            background: rgba($color-white, .6);
        }

        &:hover,
        &:focus,
        &:active {
            color: rgba($color-white, 1);
            border-bottom: 1px solid $color-white;
        }
    }


    @media screen and (max-width: 34.9375em) { /* 559px */
        a,
        a:after,
        span {
            display: none;
        }
        a:last-of-type {
            display: inline;

            &:before {
                content: '\003c ';
                padding-right: 4px;
            }
        }
    }
}


