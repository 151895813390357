

$primary-font: 'proxima-nova', Helvetica, Arial, sans-serif;

$primary-font-weight: 400;

$accent-font: 'roboto-slab',  serif;

$font-small: 1.2rem;

$font-regular: 1.6rem;
