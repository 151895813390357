h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 1.5rem 0;
    font-family: $accent-font;
    font-weight: bold;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 3rem;
}

table + h1,
table + h2,
table + h3,
table + h4,
table + h5,
table + h6 {
    margin-top: 3rem;
}


h1, .h1 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-family: $primary-font;
    text-transform: uppercase;
}

h2, .h2 {
    font-size: 2.6rem;
    line-height: 3.2rem;
}

h3, .h3 {
    font-size: 2.1rem;
    margin-bottom: 0;
}

h4, .h4 {
    font-size: 1.8rem;
    margin-bottom: 0;
}

h5, .h5 {
    font-size: 1.8rem;
    margin-bottom: 0;
}

h6, .h6 {
    font-size: 1.6rem;
}

p,
ol,
ul,
dl,
address {
    margin: 0 0 1.5rem;
}

small {
    font-size: 1.28rem;
}

@media screen and (min-width: 22.5em) {/* 360px */
    h1, .h1 {
        font-size: 2.6rem;
        line-height: 2.6rem;
    }
}

@media screen and (min-width: 25em) {/* 400px */
    h1, .h1 {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    h2, .h2 {
        font-size: 3.1rem;
        line-height: 4.8rem;
    }
}

/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */

    h1, .h1 {
        font-size: 5.6rem;
        line-height: 5.6rem;
    }

    h2, .h2 {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    h3, .h3 {
        font-size: 2.4rem;
        margin-bottom: 0.75rem;
    }

    h4, .h4 {
        font-size: 2rem;
    }

    h5, .h5 {
        font-size: 1.8rem;
        margin-bottom: 0;
    }

}


.heading-inline {
    display: inline;
    float: left;
    padding: 0;
}

.two-part-heading {
    text-transform: uppercase;
    font-family: $accent-font;
    color: $color-grey-04;
    line-height: 1.1;
    font-size: 1.8rem;

    &__part-2 {
        display: block;
        font-family: $primary-font;
        color: $color-grey-08;
        font-size: 2.8rem;

        .two-part-heading--reverse & {
            color: $color-white;
        }
    }

    @media screen and (min-width: 48em) { /* 768px */
        font-size: 2.6rem;

        &__part-2 {
            font-size: 4.3rem;
        }
    }
}
