
.date-card {
    background: $color-primary;
    color: $color-white;
    text-transform: uppercase;
    text-align: center;
    padding: $spacing*2;
    max-width: 94px;
    line-height: 1;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    transition: 300ms ease-in-out;

    &__day {
        font-size: 3.8rem;
        line-height: 1;
        font-weight: bold;
    }

    :where(a).media-object--date:hover &,
    :where(a).media-object--date:focus &,
    :where(a).media-object--date:active & {
        background-color: $color-secondary;
    }
}

.date-card--small {
    padding: $spacing*1;
    max-width: 70px;

    .date-card__day {
        font-size: 3.6rem;
    }
}
