.section--header {
    background: $color-secondary;
    color: $color-white;
    position: relative;
    padding: $spacing 0;
    display: none;

    @media screen and (min-width: 62em) { /* 992px */
        display: block;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 10px;
        background: rgba($color-secondary, .3);
    }

    .header__logo {
        display: none;

        a {
            display: block;
        }

        &__img {
            width: 320px;
        }
    }

    @media screen and (min-width: 62em) { /* 992px */
        .header__logo {
            display: block;
        }
    }


}


.container--header {
    @media screen and (min-width: 92em) {/* 1472px */
        padding-left: 5em;
        padding-right: 5em;
    }

}



.header {

    .row {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;

    }

    &__logo {
        -ms-flex: 0 1 320px;
        -webkit-box-flex: 0;
        flex: 0 1 320px;
        max-width: 320px;
        transition: all 350ms ease-in-out;

        @media only screen and (min-width: 62em) { /* 992px */
            position: relative;
            z-index: 2;

            &:after,
            &:before {
                content: "";
                position: absolute;
                top: calc(50% - 4px);
                transform: translateY(-50%);
                width: 100vw;
                height: 37px;
                background-color: $color-white;
                opacity: 1;
                transition: all 350ms ease-in-out;
            }

            &:after {
                left: 0;
                z-index: -1;
                width: calc(100vw - 13px);
            }

            &:before {
                right: 100%;
            }
        }
    }

    .header__rhs {
        padding: 20px 0 24px 24px;
        max-width: calc(100% - 320px);

        -ms-flex: 1 1 auto;
        -webkit-box-flex: 1;
        flex: 1 1 auto;

        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;

        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;

        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;

        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }

    &__topnav {
        margin-bottom: $spacing;

        font-size: 1.4rem;
        text-transform: uppercase;
        ul {
            list-style: none;
            display: inline-block;
            margin: 0;

            li {
                display: inline-block;
            }
        }
        &__list {
            &__item {
                border-right: 2px solid $color-accent-01;
                padding: 0 $spacing*2;
            }
            &__item--members {
                border: 0px;
                padding: 0;
                text-align: left;

                ul {
                    display: block;
                }

                @media screen and (min-width: 35em) { /* 560px */
                    ul {
                        display: inline-block;
                    }
                }
            }
        }
        &__link {
            color: $color-white;
            font-weight: bold;

            &:hover,
            &:focus,
            &:active {
                color: $color-accent-01;

                & svg path {
                    fill: $color-accent-01;
                }
            }
        }
        &__cart {
            font-weight: normal;
            text-decoration: none;

            &__label{
                display: none;
            }

            @media screen and (min-width: 35em) { /* 560px */
                &__label{
                    display: inline;
                }
            }
        }
        &__login-btn {
            margin-left: $spacing*2;

            &:hover,
            &:focus,
            &:active {
                background-color: $color-primary;
                border-color: $color-primary;
            }
        }
    }

    .primary-nav {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

@media screen and (min-width: 62em) { /* 992px */
    .scrolled #header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }

    .scrolled #wrap {
        margin-top: 220px;
    }

    .header-shrink .header__logo {
        -ms-flex: 0 1 220px;
        -webkit-box-flex: 0;
        flex: 0 1 220px;
        max-width: 220px;

        &:after,
        &:before {
            top: calc(50% - 2px);
            transform: translateY(-50%);
            height: 26px;
        }

        &:after {
            width: 228px;
        }
    }
}
