.children-gallery-list {
    list-style-type: none;
    margin: 20px -15px 10px;
    padding: 0;
}

.widget-ChildrenGallery .children-gallery-list-item {
    margin: 15px;
    max-width: 100%;
    position: relative;
    background-color: $color-grey-01;
    width: calc(50% - 30px);
    float: left;
}

.children-gallery-list-item a {
    display: block;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        top: calc(100% - 10px);
        left: 0;
        right: 0;
        height: 10px;
        z-index: 1;
        background: rgba(0,0,0,0.15);
    }

    &:after {
        content: '';
        position: absolute;
        top: calc(100% - 10px);
        left: 0;
        right: 100%;
        height: 10px;
        z-index: 1;
        background: $color-secondary;
        transition: all 400ms ease-in-out;
    }

    &:hover:after,
    &:focus:after,
    &:active:after {
        right: 0;
    }
}

.widget-ChildrenGallery .children-gallery-list-item-link {
    display: block;
    text-decoration: none;
    color: inherit;
}

.widget-ChildrenGallery .children-gallery-list-item-image {
    margin-bottom: 12px;
}

.children-gallery-list-item-image-placeholder {
    // padding-top: 86.666666667%;
    margin-bottom: 10px;
    position: relative;
    // background: #f0f0f0 no-repeat center center;
    // background-image: url(../images/img_fallback.png);
    // background-size: contain;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
    opacity: 0.9;
}

.widget-ChildrenGallery .children-gallery-list-item-title {
    margin: 0;
    font-size: 1.6rem;
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 10px;
    font-family: "roboto-slab",serif;
    font-weight: bold;
    transition: all 400ms ease-in-out;

    @media screen and (min-width: 38.125em) { /* 610px */
        font-size: 2rem;
    }

}

.widget-ChildrenGallery .children-gallery-list-item-anchor {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@supports (display: grid) {
    .children-gallery-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        row-gap: 1.6rem;
        column-gap: 2.4rem;
        justify-items: center;
        margin-left: 0;
        margin-right: 0;
    }
    .children-gallery-list.-clearfix:before,
    .children-gallery-list.-clearfix:after {
        content: none;
    }
    .children-gallery-list-item,
    .widget-ChildrenGallery .children-gallery-list-item {
        max-width: 100%;
        width: 100%;
        margin: 0;
        float: none;
        margin-bottom: 0;
        background-color: transparent;
    }
    .widget-ChildrenGallery .children-gallery-list-item-link {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .widget-ChildrenGallery .children-gallery-list-item-title {
        order: 1;
        margin-bottom: 0;
        margin-top: 10px;
        padding-left: 0;
        padding-right: 0px;
        padding-bottom: 8px;
        line-height: 1.12;
        flex: 1 auto;
    }
    .widget-ChildrenGallery .children-gallery-list-item-image {
        order: 2;
        margin-bottom: 0;
    }
    /* BP Smaller */
    @media screen and (min-width: 38.125em) { /* 610px */
        .children-gallery-list {
            column-gap: 3.6rem;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        }
    }
}
