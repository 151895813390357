
.section {
    padding-top: $spacing*2;
    padding-bottom: $spacing*2;
}

.section-small {
    padding-top: 15px;
    padding-bottom: 15px;
}

.section-large {
    padding-top: 40px;
    padding-bottom: 40px;
}

.section__cta-btn {
    padding-top: 30px;
    padding-bottom: 10px;
    text-align: center;

    .button {
        padding-left: $spacing*4;
        padding-right: $spacing*4;
    }
}

.section--t0 {
    padding-top: 0;
}

@media screen and (min-width: 23.75em) { /* 380px */
    .section {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .section-small {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .section-large {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .section--t0 {
        padding-top: 0;
    }
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .section-small {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .section-large {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .section__cta-btn {
        padding-top: 60px;
        padding-bottom: 20px;

        .button {
            min-width: 31.5%;
        }
    }

    .section--t0 {
        padding-top: 0;
    }
}
