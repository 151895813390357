.media-object-list {
    padding: 0;
    margin: 0;
    list-style: none;

    @include clearfix;
}

.media-object-list__item {
    padding: $spacing 0;
    border-top: 2px solid $color-grey-02;

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */
        padding: $spacing*2.5 0;
    }
    /* BP large */
    @media screen and (min-width: 75em) { /* 1200px */
        padding: $spacing*3.5 0;
    }

}

.media-object-list__item:first-child {
    border-top: 0px;
}

.media-object-list:after {
    clear: both;
}
