.widget-FileList {
    background: $color-grey-01;
    border-left: 6px solid $color-primary;
}

.widget-FileList a {
    color: $color-black;
    border-bottom: 1px solid rgba( $color-secondary, 0);
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
    border-bottom: 1px solid rgba( $color-secondary, 1);
}
