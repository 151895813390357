.form-section {
    width: 100%;
}
.form-section {
    width: 100%;
    border-color: #F0F0F0;
    border-collapse: collapse;
    margin: 8px 0 14px;
}
.form-section tr {
    border: none;
}
.form-section tr:first-child {
    border-top: 1px solid #F0F0F0;
}
.form-section tr:last-child {
    border-bottom: 1px solid #F0F0F0;
}
.form-section tr:nth-child(even) {
    background-color:#FFF;
}
.form-section tr:nth-child(odd) {
    background-color:#F0F0F0;
}
.form-section th,
.form-section td {
    padding: 8px 12px;
    border: none;
}
.form-section th {
    text-align: left;
    font-weight: bold;
    padding-right: 8px;
    background-color: transparent;
    width: 30%;
}
.form-section td {
    text-align: left;
}

.field-info {
    width: 80px;
    font-size: 1.4rem;
}

span.field-info {
    font-weight: bold;
    padding: 4px;
    display: inline-block;
}

.form-section .textbox,
.form-section .field-input input,
.form-section .field-input textarea,
.form-section select {
    padding: 4px 8px;
}
.form-section select {
    border: 1px solid $color-grey-04;
    background-color: $color-grey-01;
}

.form-section .textbox,
.form-section select,
.form-section .field-input input,
.form-section .field-input textarea,
.form-section .field-input select {
    background-color: #fff;
}

th.radio,
td.radio {
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    width: 40px;
    border-right: 1px solid #f0f0f0;
}
