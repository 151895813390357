
.section--quick-links {
    background: $color-white;
    padding-left: 10px;
    padding-right: 10px;

    .two-part-heading {
        margin-bottom: 24px;

        @media screen and (min-width: 48em) { /* 768px */
            margin-bottom: 64px;
        }

        & span {
            position: relative;
            display: inline-block;
            padding-bottom: 24px;

            @media screen and (min-width: 48em) { /* 768px */
                padding-bottom: 32px;
            }

            &:after {
                content: '\00a0';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 6px;
                background: $color-secondary;
            }
        }
    }
}

.section--subscribe {
    background: $color-grey-07;
    @include reverse-text;
    padding-left: 10px;
    padding-right: 10px;


    & .two-part-heading__part-2 span {
        position: relative;
        display: inline-block;

        &:after {
            content: '\00a0';
            position: absolute;
            top: -36px;
            left: 0;
            right: 0;
            height: 6px;
            background: $color-grey-02;

            @media screen and (min-width: 22.5em) {/* 360px */
                top: -59px;
            }

            @media screen and (min-width: 48em) { /* 768px */
                top: -89px;
            }
        }

    }

}


.subscribe {

    &__enews {

        &__heading {
            text-transform: uppercase;
            line-height: 1.3;
        }
    }

    &__socials {
        margin: $spacing*4 0 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &__heading {
            text-transform: uppercase;
            line-height: 1.3;
            flex: 1 0 auto;
            margin: 0;
        }
    }

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */


        &__header {
        }
        &__enews {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &__heading {
                flex: 0 1 auto;
                padding-right: $spacing*4;
                margin: 0;
            }

            &__form {
                flex: 1 1 auto;
            }
        }
        &__socials {

        }
    }

    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-gap: $spacing*4;

        &__header {
            grid-area: 1 / span 11;
        }
        &__enews {
            grid-column: 1 / span 9;
        }
        &__socials {
            margin-top: 0;
            grid-column: 10 / span 3;

            .social-list {
                margin: 0;
            }
        }
    }


    /* BP xlarge */
    @media screen and (min-width: 92em) {/* 1472px */
        &__header {
            grid-area: 1 / span 3;
        }
        &__enews {
            grid-column: 4 / span 6;
        }
        &__socials {
            margin-top: 0;
            grid-column: 10 / span 3;

            .social-list__item {
                padding: 0 $spacing*4;
            }
        }
    }

}


.section--contact-us {
    background: $color-white;

    .section {
        padding-left: 10px;
        padding-right: 10px;
    }



}

.contact-us {

    &__heading {
        color: $color-primary;
        font-size: 2.4rem;
    }

    &__map {
        background: url(../images/img_location.png) top 20% center no-repeat;
        background-size: cover;
        padding-top: 60%;
        display: block;
    }
/* BP small */
    @media screen and (min-width: 48em) { /* 768px */

        &__details {
            &__text-cols {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: $spacing*3;

                p {
                    border-right: 1px solid $color-grey-05;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }

        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: $spacing*4;
    }

    /* BP xlarge */
    @media screen and (min-width: 92em) {/* 1472px */
        grid-template-columns: 364px auto;

        &__details {
            &__text-cols {
                grid-gap: $spacing*4;
            }

        }
    }
}

.terms-footer {
    font-size: 1.5rem;

    &-sm-links {
        display: block;
        margin-top: $spacing*2;

        a {
            text-decoration-color: transparent;
        }

        a:hover,
        a:focus,
        a:active {
            text-decoration-color: inherit;
        }
    }

    &__memberships {
        font-family: $accent-font;
        text-align: center;
        margin-top: 40px;

        &__logo {
            max-height: 76px;
            width: auto;
            display: block;
            margin: 12px auto
        }

        &__logo--sasta {
            max-height: 52px;
        }

        @media screen and (min-width: 35em) { /* 560px */
            text-align: left;

            &__logo {
                margin: 6px;
                display: inline-block;
            }
        }

        @media screen and (min-width: 62em) { /* 992px */
            margin-top: 0;
        }
    }

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */

        &-sm-links {
            display: inline;
            margin-top: 0;
        }

        &__memberships {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &__logo {
                margin: 0;
                margin-left: $spacing*4;
            }
        }
    }
    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */
        // grid fallback layout for IE11
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: $spacing*2;
        align-items: center;

        p {
            margin: 0;
        }

    }

    @media screen and (min-width: 92em) {/* 1472px */
        &-sm-links {
            display: inline;
            margin-top: 0;
        }

    }
}

.section--terms-footer {
    background: $color-secondary;
    @include reverse-text;
    padding-left: 1em;
    padding-right: 1em;
}

@media screen and (min-width: 22.5em) {/* 360px */

    .section--quick-links {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .section--subscribe {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .section--contact-us .section {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .section--terms-footer {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

}

@media screen and (min-width: 48em) { /* 768px */

    .section--quick-links {
        padding-left: 3em;
        padding-right: 3em;
    }

    .section--subscribe {
        padding-left: 3em;
        padding-right: 3em;
    }

    .section--contact-us .section {
        padding-left: 3em;
        padding-right: 3em;
    }

    .section--terms-footer {
        padding-left: 3em;
        padding-right: 3em;
    }

}

@media screen and (min-width: 75em) { /* 1200px */

    .section--quick-links {
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 6em;
    }

    .section--subscribe {
        padding-left: 5em;
        padding-right: 5em;
    }

    .section--contact-us .section {
        padding-left: 0;
        padding-right: 5em;
    }

    .section--terms-footer {
        padding-left: 5em;
        padding-right: 5em;
    }

}

// old stuff

.section--pre-footer {
    background: $color-primary;
    @include reverse-text;
}

.footer-heading {
    font-size: 2.4rem;
    line-height: 3.2rem;
}

.section--footer {
    background: $color-secondary;
    @include reverse-text;
    padding: $spacing--xlarge 0;
    text-align: center;
}

.footer__text {
    padding-top: $spacing--medium;
    padding-bottom: $spacing--medium;
}

.footer__text p {
    margin: 0;
    display: inline;
}

.footer__text p:before {
    content: " | ";
}

.footer__text p:first-child:before {
    content: none;
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section--pre-footer .block-list {
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        -webkit-column-gap: 40px;
           -moz-column-gap: 40px;
                column-gap: 40px;
    }
    .section--footer {
        text-align: auto;
    }
    .footer__text {
        padding: 0;
    }
}
