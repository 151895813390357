.card-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        margin-top: $spacing*2;
    }
}

@supports(display:grid) {
    .card-list {
        display: grid;
        grid-gap: $spacing--xlarge;

        /* BP small */
        @media screen and (min-width: 48em) { /* 768px */
            grid-template-columns: 1fr 1fr;
        }

        /* BP xlarge */
        @media screen and (min-width: 92em) {/* 1472px */
            grid-template-columns: 1fr 1fr 1fr 1fr ;
        }
    }
    .card-list__item {
       margin-top: 0;
   }

   .card-list--events {

        /* BP small */
        @media screen and (min-width: 48em) { /* 768px */
            grid-template-columns: 1fr 1fr 1fr 1fr;

            & .card-list__item {
                grid-column: span 2;
            }
        }



        @media screen and (min-width: 92em) {/* 1472px */
            grid-template-columns: 1fr 1fr 1fr;

            & .card-list__item {
                grid-column: span 1;
            }

            &.card-list--single-row .card-list__item:nth-child(n+4) {
                display: none;
            }
        }
   }
}
