
.product-list {
    list-style-type: none;
	padding: 0;
    margin: -20px -20px 0;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product-list__item {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;

    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: calc(100% - 40px);
    text-align: center;
    float: left;
    border: 1px solid #ccc;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
}

.product-list__item > * {
    -ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 100%;
}

.product-list__item__out-stock {
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    border: 2px solid #a8a8a8;
    opacity: 0.66;
    outline: none;
    cursor: not-allowed;
    text-transform: lowercase;
    font-size: 1.6rem;
    line-height: 1;
    padding: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: bold;

    @media screen and (min-width: 48em) {
        padding: 8px 12px 8px;
    }

}

.product-list__item__title {
    font-size: 1.6rem;
    margin: 0;
    margin-bottom: 0;
    font-family: "proxima-nova",Helvetica,Arial,sans-serif;
    line-height: 1.2;
    width: 100%;
    display: block;
    font-weight: bold;
}

.product-list__item__title a {
	text-decoration: none;
	color: inherit;
}

.js--add-to-event {
    cursor: pointer;
}

.product-list__item__price {
    margin-bottom: 7px;
}

.product-list__item__thumb a {
    display: block;
}

.product-list__item__thumb__img {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
}


.product-list__item__buttons p {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
    margin-bottom: 0;
}

.product-list__item__buttons p > * {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 7px;
    margin-bottom: 0;
}

/* ---- Product list ---- */
/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */
	.product-list__item {
		width: calc(50% - 40px);
    }

    .product-list__item__content {
        margin-top: auto;
    }
}


/* BP medium */
@media screen and (min-width: 62em) { /* 992px */
    .product-list__item {
        width: calc(50% - 40px);
    }
}
