.site-search-form {
    margin: 0 0 $form-spacing*5;

    @include clearfix;

    input {
        max-width: calc(100% - 95px);
        float: left;
    }

    input[type="submit"] {
        max-width: none;
        max-width: unset;
        float: right;
    }
}

.site-search-form--header {
    margin: 0;
}

.search-result {
    border-top: 1px solid $color-grey-04;
    margin: $form-spacing*2.5 0;
    padding: $form-spacing*2.5 0;
}

.search-result p:last-child {
    margin-bottom: 0;
}

