.product-detail {
    display: grid;
    gap: $spacing;

    @include screen($bp560) {
        gap: $spacing*3;
        grid-template-columns: 1fr 2fr;
    }
    @include screen($bp1200) {
        grid-template-columns: 1fr 2.25fr;
        gap: $spacing*5;
    }


    &__name {
        text-wrap: balance;
        line-height: 1.25;
    }

    &__price {
        font-size: 1.5em;
    }
}
