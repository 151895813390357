
.button {
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: $color-white;
    background-color: $color-primary;
    text-decoration: none;
    border-radius: 0px;
    border: 2px solid transparent;
    outline: none;
    opacity: 1;
    position: relative;
    text-transform: lowercase;
    font-family: $primary-font;
    font-weight: $primary-font-weight;
    line-height: 1;
    transition: all 250ms ease-in-out;
    font-weight: 600;
}


.button:hover,
.button:focus,
.button:active {
    background-color: darken($color-accent-01, 10%);
    color: $color-white;
}


/* Small */
.button,
.button-small,
.button--small {
    font-size: 1.6rem;
    line-height: 1;
    padding: $spacing*1.5;
    padding-top: $spacing - 2px;
    padding-bottom: $spacing - 2px;
}


/* - Button icons - */
.button__icon {
    display: inline-block;
    vertical-align: top;
    width: $spacing--medium;
    height: $spacing--medium;
}


.button__icon--right {
    margin-left: $spacing--xsmall;
    margin-right: -($spacing--xxsmall);
}

.button__icon--left {
    margin-right: $spacing--xsmall;
    margin-left: -($spacing--xxsmall);
}

.button__icon svg {
    width: 16px;
    height: 16px;
}

.button__icon svg path {
    fill: $color-white;
    transition: fill 250ms ease-in-out;
}

.button__icon--primary svg path {
    fill: $color-primary;
}

.button__icon--secondary svg path {
    fill: $color-secondary;
}

.button:hover .button__icon--light-purple svg path {
    fill: $color-white;
}


/* - Button styles - */


.reverse-text .button:hover,
.reverse-text .button:focus,
.reverse-text .button:active {
    opacity: 1;
}

/* Alternative */
.button--alt {
    background-color: darken($color-accent-01, 10%);
    color: $color-white;
}

.button--alt:hover,
.button--alt:focus {
    background-color: darken($color-primary, 10%);
    color: $color-white;
}

/* Subtle */
.button--subtle-grey {
    background-color: transparent;
    border: 2px solid $color-grey-02;
    text-transform: uppercase;
    font-weight: normal;
    color: $color-grey-05;
}

.button--subtle-grey:hover,
.button--subtle-grey:focus {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;
}

/* Subtle */
.button--subtle-white {
    background-color: transparent;
    border: 2px solid $color-grey-02;
    text-transform: uppercase;
    font-weight: normal;
    color: $color-white;
}

.button--subtle-white:hover,
.button--subtle-white:focus {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;
}

/* Primary Subtle */
.button--subtle-primary {
    background-color: transparent;
    border: 2px solid lighten($color-primary, 5%);
    text-transform: uppercase;
    color: $color-white;
}

.button--subtle-primary:hover,
.button--subtle-primary:focus {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;
}

/* Secondary Subtle */
.button--subtle-secondary {
    background-color: transparent;
    border: 2px solid lighten($color-secondary, 5%);
    text-transform: uppercase;
    color: $color-secondary;
}

.button--subtle-secondary:hover,
.button--subtle-secondary:focus {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;
}

/* Red Warning Button */
.button--warning {
    background-color: $color-utility-warning;
    color: $color-white;
}

.button--warning:hover,
.button--warning:focus {
    background-color: darken($color-utility-warning, 10%);
    color: $color-white;
}

/* Grey */
.button--neutral {
    background-color: $color-grey-02;
    color: $color-grey-08;
}

.button--neutral:hover,
.button--neutral:focus {
    background-color: $color-grey-03;
    color: $color-grey-08;
}

.button--neutral svg path{
    fill: $color-grey-08;
}

/* Green */
.button--positive {
    background-color: $color-utility-positive-subtle;
    color: $color-white;
}

.button--positive:hover,
.button--positive:focus {
    background-color: $color-utility-positive;
    color: $color-white;
}

/* Block */
.button--block {
    display: block;
    width: 100%;
}

/* Disabled/unavailable button style */
.button[disabled],
.button--disabled,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
    border: none;
    opacity: 0.4;
    cursor: default;
    -webkit-box-shadow: none;
            box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
    padding: 0;
    border: 0;
}



/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    /* Regular */
    .button {
        padding: $spacing*1.5 $spacing--medium $spacing*1.5;
        line-height: 1;
    }
    .button-small,
    .button--small {
        padding: $spacing $spacing*1.5 $spacing;
    }

}

/* BP medium */
@media only screen and (min-width: 62em) { /* 992px */
    /* Large */
    .button-large,
    .button--large {
        font-size: 2.4rem;
        padding: $spacing--medium $spacing--xlarge;
    }

    .button--large .button__icon {
        width: $spacing--medium*1.5;
        height: $spacing--medium*1.5;
    }

    .button--large .button__icon svg {
        width: $spacing--medium*1.5;
        height: $spacing--medium*1.5;
    }

    .button--large .button__icon--right {
        margin-left: $spacing--xsmall;
        margin-right: -($spacing);
    }

    .button--large .button__icon--left {
        margin-right: $spacing--xsmall;
        margin-left: -($spacing);
    }

}


/* Legacy Grey */
.button.button-grey {
	background: #a8a8a8;
}

.button.button-grey:hover,
.button.button-grey:focus {
	background: #878787;
}


/* shopping cart button */
.button--cart {
    padding-right: 32px;
    background-image: url(../images/icon-system/icon_cart.svg);
    background-size: 16px auto;
    background-position: center right 12px;
    background-repeat: no-repeat;

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */
        padding-right: 36px;
        background-position: center right 16px;
    }
}

.button-block {
    display: block;
    width: 100%;
}
