.social-list {
    list-style: none;
    padding: 0;
    margin: 0px;
    margin-left: -$spacing*2;
    margin-right: -$spacing*2;
}

.social-list__item {
    display: inline-block;
    padding: 0 $spacing*2;
    margin-right: -$spacing;
    border-left: 2px solid $color-grey-02;

    &:first-child {
        border-left: 0;
    }

    & a {
        color: inherit;
        display: block;
    }

    & svg {
        display: block;
    }

    & svg path {
        transition: fill 200ms ease-in-out;
    }

}



.social-list--mobile {
    border-top: 1px solid #f0f0f0;
    padding-left: 0;
    margin: 0;

    @media screen and (min-width: 62em) { /* 992px */
        display: none;
    }

    .social-list__item {
        border-left: 0;
        padding-top: $spacing*1.5;
        padding-bottom: $spacing;
        padding-left: $spacing*2;
        padding-right: $spacing*2;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: $color-primary;
            }
        }

        & a:hover svg path,
        & a:focus svg path,
        & a:active svg path {
            fill: $color-primary;
        }

    }
}

.social-list--topnav {
    position: relative;
    top: -1px;

    .social-list__item {
        border-left: 0;
        padding: 0 $spacing;
        height: 22px;
        margin-right: -2px;

        a {
            display: inline-block;
        }

        svg  {
            width: 16px;
            height: 16px;

            path {
                fill: $color-white;
            }
        }

        &:last-child {
            border-right: 2px solid $color-primary;
        }

        & a:hover svg path,
        & a:focus svg path,
        & a:active svg path {
            fill: $color-primary;
        }

    }
}
