* {
    margin: 0;
}

html,
body {
    background: $color-grey-01;
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    line-height: 1.5;
    font-size: $font-regular;
    color: $color-grey-07;
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
