.widget-RelatedLinks {
    margin-bottom: 25px;
}

.widget-RelatedLinks ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.widget-RelatedLinks a {
    text-decoration: none;
    color: #2a333e;
    display: block;
    padding: 9px 0;
}


/* - Depth 1 - */
.widget-RelatedLinks li.depth1 {
    border-bottom: 1px solid #e6e1e1;
    position: relative;
}
.widget-RelatedLinks li.depth1:last-child {
    border-bottom: none;
}
.widget-RelatedLinks li.depth1 > a {
    padding: 9px 0;
    position: relative;
}
.widget-RelatedLinks li.depth1 > a:hover,
.widget-RelatedLinks li.depth1 > a:focus,
.widget-RelatedLinks li.depth1 > a:active {
    color: $color-black;
}
.widget-RelatedLinks li.depth1:before {
    content: '';
    background: $color-grey-02;
    position: absolute;
    height: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    transition: 280ms ease-in-out;
}
.widget-RelatedLinks li.depth1:hover:before {
    height: 4px;
}

/* On */
.widget-RelatedLinks li.depth1.on {
    border-left: 3px solid $color-primary;
}
.widget-RelatedLinks li.depth1.on:before {
    display: none;
}
.widget-RelatedLinks li.depth1.on > a {
    padding-left: 20px;
    font-weight: bold;
}


/* - Depth 2 - */
.widget-RelatedLinks ul.depth2 {
    padding-bottom: 8px;
}
.widget-RelatedLinks li.depth2 {
    font-size: 1.4rem;
}
.widget-RelatedLinks li.depth2 a:before {
    content: "- ";
    display: inline-block;
    width: 12px;
    position: relative;
    left: -12px;
    margin-right: -12px;
}
.widget-RelatedLinks li.depth2 a {
    transition: text-decoration-color 200ms ease-in-out;
    text-decoration: underline;
    text-decoration-color: rgba($color-primary, 0);
}
.widget-RelatedLinks li.depth2:hover a {
    text-decoration: underline;
    text-decoration-color: rgba($color-primary, 1);
}
.widget-RelatedLinks li.depth2 a {
    padding: 4px 0 4px 34px;
    border-bottom: 2px solid rgba($color-grey-02, .0);
}

/* Hover */
.widget-RelatedLinks li > a:hover {
    color: #000;
}

/* On */
.widget-RelatedLinks li.depth2.on {
}

.widget-RelatedLinks li.depth2.on > a {
    color: $color-primary;
    font-weight: bold;
}
