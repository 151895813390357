
.banner-basic {
    background: #000;
    color: $color-white;
    position: relative;
    height: calc((100vw - 1em) * 0.55);

    @media screen and (min-width: 22.5em) {/* 360px */
        height: calc((100vw - 2em) * 0.5851);
    }

    @media screen and (min-width: 30em) {/* 480px */
        height: calc((100vw - 3em) * 0.5851);
    }

    @media screen and (min-width: 35em) { /* 560px */
        height: auto;
    }

    @media screen and (min-width: 62em) { /* 992px */
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        background: $color-primary;
    }

    &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        opacity: .5;
        overflow: hidden;

        img {
            object-fit: cover;
        }

        @media screen and (min-width: 62em) { /* 992px */
            position: relative;
            opacity: 1;
            order: 2;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25.4%;
            flex: 0 0 25.4%;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
            }
        }

    }

    &__text {
        padding-top: $spacing;
        padding-left: 1em;
        padding-right: 1em;
        background: rgba(0,0,0,0.6);

        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 100%;

        @media screen and (min-width: 22.5em) {/* 360px */
            padding-left: 1.5em;
            padding-right: 1.5em;
        }

        @media screen and (min-width: 35em) { /* 560px */
            height: auto;
        }

        @media screen and (min-width: 48em) { /* 768px */
            padding-left: 3em;
            padding-right: 3em;
        }

        @media screen and (min-width: 62em) { /* 992px */
            background: transparent;

            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-left: 5em;
            padding-right: 5em;
        }

        &__section-title {
            margin-bottom: 0;
            opacity: .6;
            text-transform: uppercase;
            font-weight: bold;
            font-family: $accent-font;
            font-size: 2.2rem;
            line-height: 1.2;

            -webkit-box-flex: 1;
            -ms-flex: 0 1 100%;
            flex: 0 1 100%;
            max-width: 100%;

            @media screen and (min-width: 48em) { /* 768px */
                font-size: 2.8rem;
            }
        }

        &__page-title {
            margin: 0;

            &__deco {
                position: relative;
                display: inline-block;
                padding-bottom: $spacing*3;

                @media screen and (min-width: 48em) { /* 768px */
                    padding-bottom: $spacing*4;
                }

                &:after {
                    content: '\00a0';
                    height: $spacing;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: lighten($color-primary, 10%);
                }
            }
        }

        div {
            max-width: 100%;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
            flex: 1 1 100%;

            margin-top: auto;
        }

        .breadcrumb {
            max-width: 100%;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
            flex: 1 1 100%;
        }
    }
}
