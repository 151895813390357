

$spacing:                       8px;

$spacing--xxsmall:              2px;

$spacing--xsmall:               4px;

$spacing--small:                8px;

$spacing--medium:               16px;

$spacing--large:                24px;

$spacing--xlarge:               32px;

$spacing--xxlarge:              64px;


// style variables
$standard-radius:               0px; // note: button radius set manually

$form-spacing:                  8px;
