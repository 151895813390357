.table--content-standard {
    width: 100%;
    margin: 2rem 0;
    border-color: #F0F0F0;
	border-collapse: collapse;
}

.table--content-standard tr {
    border: none;
}

.table--content-standard tr:first-child {
	border-top: 1px solid #F0F0F0;
}

.table--content-standard tr:last-child {
	border-bottom: 1px solid #F0F0F0;
}

.table--content-standard tr:nth-child(even) {
    background-color:#FFF;
}

.table--content-standard tr:nth-child(odd) {
    background-color:#F0F0F0;
}

.table--content-standard caption {
    text-align: left;
    padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
    font-weight: bold;
    background-color: transparent;
    font-size: 1.7rem;
}

.table--content-standard td {
    background-color: transparent;
}

.table--content-standard th,
.table--content-standard td {
    border: none;
    text-align: left;
}
