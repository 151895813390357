
.icon {
    display: inline-block;
    vertical-align: middle;

    &--with-count {
        position: relative;
    }

    &__count {
        display: block;
        width: 18px;
        height: 18px;
        background: $color-accent-02;
        color: $color-white;
        border-radius: 100px;
        text-align: center;
        font-size: 1rem;
        line-height: 1;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

.icon svg {
    width: 16px;
    height: 16px;
}

.icon path {
    transition: fill $link-transition
}

.icon--24px svg {
    width: 24px;
    height: 24px;
}

.icon--32px svg {
    width: 32px;
    height: 32px;
}

.icon--40px svg {
    width: 40px;
    height: 40px;
}

.icon--48px svg {
    width: 48px;
    height: 48px;
}

.icon--52px svg {
    width: 52px;
    height: 52px;
}

.icon--60px svg {
    width: 60px;
    height: 60px;
}

.icon--68px svg {
    width: 68px;
    height: 68px;
}


/* Icon system colours */

.icon--primary svg path {
    fill: $color-primary;
}

.icon--secondary svg path {
    fill: $color-secondary;
}

.icon--alternative svg path {
    fill: $color-alternative;
}

.icon--white svg path {
    fill: $color-white;
}

.icon--accent-01 svg path {
    fill: $color-accent-01;
}

.icon--accent-02 svg path {
    fill: $color-accent-02;
}
