
.home-banner {
    background: $color-white;
    @include clearfix;

    /* BP large */
    @media screen and (min-width: 75em) { /* 1200px */
        border-bottom: $spacing solid $color-grey-02;

        &__grid {
            display: flex;
        }

        .card-wrapper,
        .card-slider {
            max-width: 64%;
            float: left;
            flex: 1 1 auto;
        }

        .hm-blog-feed {
            max-width: 36%;
            float: right;
            width: 100%;
        }
    }
}

.hm-promos {

    .col-xs-12 {
        padding: 1rem;
    }

    // IE11 flex bug workaround
    .card__img,
    .card__img img {
        max-height: 206px;
    }
    // reset for non-buggy browsers
    @supports (display: flex) {
        .card__img,
        .card__img img {
            max-height: none;
        }
    }
}

/* BP xlarge */
@media screen and (min-width: 92em) {/* 1472px */
    .hm-promos {
        margin-right: -2rem;
        margin-left: -2rem;

        .col-xs-12 {
            padding: 2rem;
        }
    }
}




.hm-blog-feed {
    padding: 2em 1em;
    border-bottom: $spacing solid $color-grey-02;
    margin-bottom: $spacing*2;

    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: 75em) { /* 1200px */
        border-bottom: none;
    }

    &__header {

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        &__btns {
            text-transform: uppercase;
            text-align: right;

            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .two-part-heading {
            position: relative;
            top: $spacing;

            @media screen and (min-width: 48em) { /* 768px */
                font-size: 1.8rem;
            }
        }

        .two-part-heading__part-2 {
            @media screen and (min-width: 48em) { /* 768px */
                font-size: 2.8rem;
            }
        }
    }

    .media-object-list {
        flex: 1 0 auto;

        display: flex;
        flex-flow: column nowrap;

        &:before {
            content: none;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            &__item:last-child {
                padding-bottom: 0;
            }
        }
    }

    .media-object__heading {
        color: #4a4949;
        font-weight: normal;
        font-family: "proxima-nova",Helvetica,Arial,sans-serif;
        line-height: 1.25;

        @media screen and (min-width: 75em) { /* 1200px */
            font-size: 2rem;
        }

        @media screen and (min-width: 92em) {/* 1472px */
            font-size: 2.2rem;
        }
    }

    &__footer {
        padding-top: 2em;
        border-top: 2px solid $color-grey-02;

        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .social-list {
            text-align: right;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: $spacing*4;
        padding-right: 5em;
        margin-bottom: 0;
        border-left: 1px solid $color-grey-02;
    }
    @media screen and (min-width: 92em) {/* 1472px */
        padding-left: 3em;
    }

}


.section--sponsors,
.section--promos {
    padding-left: 1em;
    padding-right: 1em;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: 48em) { /* 768px */
        padding-left: 3em;
        padding-right: 3em;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 6em;
    }

    &__header {
        margin-bottom: $spacing*2;

        @media screen and (min-width: 48em) { /* 768px */
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }

    .two-part-heading {
        margin-bottom: 24px;

        @media screen and (min-width: 48em) { /* 768px */
            margin-bottom: 64px;
        }

        & span {
            position: relative;
            display: inline-block;
            padding-bottom: 24px;

            @media screen and (min-width: 48em) { /* 768px */
                padding-bottom: 32px;
            }

            &:after {
                content: '\00a0';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 6px;
                background: $color-secondary;
            }
        }
    }
}

.section--promos {
    margin-bottom: $spacing*2;
    border-bottom: $spacing solid $color-grey-02;

    @media screen and (min-width: 48em) { /* 768px */
        margin-bottom: $spacing*4;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        margin-bottom: $spacing*6;
    }
}

.section--sponsors__header {
    text-transform: uppercase;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .two-part-heading {
        margin-right: 20px;
    }

    &__link {
        text-align: right;
    }
}

.sponsor-logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__link {
        display: block;
        max-width: $spacing*15;
        max-height: $spacing*15;
        padding: $spacing*2;
    }

    &__img {
        width: auto;
        margin: auto;
        height: $spacing*11;
        object-fit: contain;
    }

    @media screen and (min-width: 75em) { /* 1200px */

        &__link {
            max-width: $spacing*22;
            max-height: $spacing*22;
        }

        &__img {
            height: $spacing*18;
        }
    }



}

