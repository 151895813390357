
/* ---- A single field and submit form ---- */

.sub-enews-form {
    border-radius: 100px;
    overflow: hidden;

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0 0 20px rgba(0,0,0,.45);
    }


    .field-input input {
        border: 0px solid $color-grey-04;
    }

    .field-element-attach-wrapper__button {
        top: 0px;
        right: 0px;
        bottom: 0px;
        padding: 0 $spacing*3 0 $spacing*2;
        background: $color-white;
    }

    &:hover .field-element-attach-wrapper__button,
    &:focus .field-element-attach-wrapper__button,
    &:active .field-element-attach-wrapper__button {
        background: $color-primary;
    }

    &:hover .field-element-attach-wrapper__button svg path,
    &:focus .field-element-attach-wrapper__button svg path,
    &:active .field-element-attach-wrapper__button svg path {
        fill: #ffffff;
    }
}
