/* --------------------------------------------------------
Blog post
-------------------------------------------------------- */
.blog-post-featured-img {
	margin: 10px 0 30px;
	width: 100%;
}

.blog-author-img {
	float:left;
	max-width: 200px;
	max-height: 300px;
	margin: 0 20px 20px 0;
}

.blog-post__details {
	border-top: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
	padding: 10px 0;
	margin-bottom: 15px;
}

.blog-item h2 {
    font-size: 2.4rem;
}

.blog-item h3 {
    font-size: 2rem;
}

.blog-item h4 {
    font-size: 1.8rem;
}

.blog-item h5 {
    font-size: 1.6rem;
}

.blog-post .blog-date p {
}

.blog-item {
	border-bottom: 1px solid #d7d7d7;
	margin: 10px 0 30px;
}

.blog-item .details,
.blog-post .details {
	margin: 0 0 20px;
}
.blog-item .details p.date,
.blog-item .details p.author,
.blog-post .blog-post__details p.date,
.blog-post .blog-post__details p.author {
	display: inline;
}

@media screen and (min-width: 650px) {
	p.blog-rss-link {
		float: right
	}
}

p.blog-rss-link img {
	width: 16px;
	margin-left: 10px;
}

.blog-post .blog-date p {
	color: #ffc400;
}

.blog-post .excerpt p.author {
	font-size: 14px;
	font-size: 1.4em;
	text-transform: uppercase;
	margin: 10px 0 20px;
}

.blog-sidebar.widget-RelatedLinks {
	padding: 0 25px;
}

.blog-comments,
.fb-comments,
.fb-comments span,
.fb-comments iframe,
.pluginSkinLight >   {
	width: 100% !important;
}

.share {
	float: left;
	width: 50%;
	margin: 25px 0;
}

	.share:not(:first-child) {
		text-align: right;
	}

	.share:not(:first-child) img {
		margin-left: 7px;
	}

	.share p {
		color: #909396;
		text-transform: uppercase;
		font-size: 15px;
		font-size: 1.5rem;
		margin: 0;
	}

.share .share-item {
	display:inline-block;
	margin: 1px;
	vertical-align: top;
}

.share .share-item.share-item-fb {
	padding-right: 28px;
	margin-top: -3px;
}

.blog-post__tags {
	border-top: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
	padding: 15px 0;
}

.blog-article__footer .share {
	width: 50%;
	float: left;
	color: #B3B7BB;
}
.blog-article__footer .share:not(:first-child) {
	text-align: right;
}

.widget-BlogsList .blog-item {
    margin-left: 0;
    margin-right: 0;
    padding: 30px;
    background: #f6f6f6;
    clear: both;
    border-bottom: 0;
}

.blog-post__read-more {
    margin-bottom: 0;
}

.blog-search-box-tall .submit-bar {
    background-color: #f6f6f6;
}
