// Default card styles

.card {
    background: $color-white;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 200ms ease-in-out;
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.33);
    }

    &:focus-within {
        color: inherit;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.33);
    }

    & a:focus {
        text-decoration: underline;
    }

    &:focus-within a:focus {
        text-decoration: none;
    }
    &__heading {
        color: $color-primary;

        a {
            color: inherit;
            text-decoration: none;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        order: 1;
        padding: 2rem;

        /* BP small */
        @media screen and (min-width: 48em) { /* 768px */
            padding: 3rem;
        }
    }

    &__desc {
        margin-top: $spacing;
    }

    &__img {
        // height: 200px;
    }
    &__img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    &__cta {
        display: inline-block;
        white-space: normal;
        text-align: center;
        text-decoration: none;
        border-radius: 0px;
        border: 2px solid $color-grey-02;
        outline: none;
        opacity: 1;
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: $primary-font-weight;
        color: $color-grey-07;
        line-height: 1;
        font-size: 1.6rem;
        line-height: 1;
        padding: $spacing*1.5;
        padding-top: $spacing*1.5 - 2px;
        padding-bottom: $spacing*1.5 - 2px;
        transition: all 200ms ease-in-out;
    }

    &:hover &__cta,
    &:focus-within &__cta,
    & a:focus &__cta {
        background: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }

}


// Event card styles



.card {
    &--event {

    }

    &--event:hover,
    &--event:focus-within {
        box-shadow: none;
    }

    &--event &__img {
        padding-top: 66%;
        background: no-repeat center center;
        background-size: cover;
        position: relative;
    }
    &--event .date-card {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 94px;
    }
    &--event &__content {
        background: $color-grey-01;
        padding-top: $spacing*6;
        position: relative;

        @media screen and (min-width: 25em) {/* 400px */
            padding: $spacing*6 $spacing*3 $spacing*3;
        }
        @media screen and (min-width: 48em) { /* 768px */
            display: flex;
            justify-content: flex-end;
        }
    }
    &--event &__tagline {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 94px;
        background: $color-grey-06;
        color: $color-white;
        text-align: center;
        text-transform: uppercase;
        padding: $spacing/2;
        z-index: 3;
    }

    &--event &__heading {
        color: $color-primary;
        font-size: 2.6rem;
        line-height: 1.2;
        flex: 1 auto;
    }

    &--event &__desc {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;

        @media screen and (min-width: 25em) {/* 400px */
            grid-gap: 3rem;
        }

        h4 {
            font-family: $primary-font;
            font-size: 1.6rem;
        }

        p {
            margin-bottom: 0;
        }

        &__when {
            padding-right: 10px;
            border-right: 2px solid $color-grey-03;
        }
    }

    &--event:hover &__cta,
    &--event:hover &__cta,
    &--event:hover &__cta,
    &--event &__cta {
        border-color: lighten($color-primary, 20%);
        margin-top: $spacing*2;
        display: block;

        order: 2;

        &:after {
            content: '!';
        }

    }

    &--event &__hover {
        /* BP large */
        @media screen and (min-width: 75em) { /* 1200px */
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $color-primary;
            color: #fff;
            padding: $spacing*6 $spacing*3 $spacing*3; // Same as card__content padding

            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: stretch;
            opacity: 0;

            &:hover,
            &:focus,
            &:active {
                opacity: 1;

            }
        }

    }

    &--event &__hover__text {
        /* BP large */
        @media screen and (min-width: 75em) { /* 1200px */
            display: block;
            color: $color-white;
            clip: auto;
            height: auto;
            margin: 0;
            padding: 0;
            position: unset;
            width: auto;

            order: 1;
            font-family: $accent-font;
            font-weight: bold;
            font-size: 2.6rem;
            line-height: 1.2;
        }

    }
}




// Icon card styles

.card {

    &--icon {
        position: relative;
        min-height: 250px;
        height: 100%;
        background: $color-primary;
        border-bottom: 6px solid darken($color-primary, 10%);
    }

    &--icon:hover,
    &--icon:focus-within {
        box-shadow: none;
    }

    &--icon &__content {
        color: $color-white;
        text-align: center;
    }

    &--icon &__heading {
        color: $color-white;
        margin-bottom: $spacing--small;

        a {
            color: $color-white;
            text-decoration: none;
        }
    }

    &--icon &__cta,
    &--icon:hover &__cta {
        color: $color-white;
        border-color: lighten($color-primary, 20%);
    }

    &--icon &__img {
        position: absolute;
        top: 0;
        bottom: -6px;
        left: 0;
        right: 0;
        height: auto;
        z-index: 1;
        background: $color-grey-01;
        padding: 1em;
        transition: all 500ms cubic-bezier(.46,.03,.52,.96);
        border-bottom: 6px solid $color-grey-02;

        /* BP small */
        @media screen and (min-width: 48em) { /* 768px */
            padding: 1.5em;
        }

        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: center;

        &:after {
            display: block;
            text-align: center;
            content: attr(data-heading);
            font-size: 2rem;
            color: $color-primary;
            font-family: $accent-font;


            /* BP small */
            @media screen and (min-width: 48em) { /* 768px */
                font-size: 2.4rem;
            }
        }

        &__icon {
            flex: 1 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 80px;
                height: 80px;
            }
        }
    }

    &--icon:hover &__img,
    &--icon:focus-within &__img {
        opacity: 0;
        z-index: -2;
    }


}


.card {

    &--slider {
        background: $color-primary;
        text-decoration: none;
        display: block;
        color: $color-white;

        &:hover,
        &:focus,
        &:active {
            color: $color-white;
            box-shadow: none;
        }

        .two-part-heading__part-2 {
            color: $color-white;
            font-family: $accent-font;
            font-weight: bold;
        }
    }


    &--slider &__img {
        background: no-repeat center center;
        background-size: 100% auto;
        padding-top: 50.5%;
        height: auto;
        position: relative;
        transition: background 400ms ease-in-out;
    }

    &--slider:hover &__img,
    &--slider:focus &__img,
    &--slider:active &__img {
        background-size: 110% auto;
    }

    &--slider &__heading {
        color: $color-white;
        position: absolute;
        bottom: 0;
        left: 2rem;
        padding-bottom: 1rem;
        margin: 0;

        &:before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 10px;
            background: lighten($color-primary, 20%);
        }

        @media screen and (min-width: 48em) { /* 768px */
            left: 3rem;
            padding-bottom: 2.5rem;
        }
    }
    &--slider &__heading:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 100%;
        height: 10px;
        background: $color-secondary;
        transition: all 400ms ease-in-out;
    }
    &--slider:hover &__heading:after,
    &--slider:focus &__heading:after,
    &--slider:active &__heading:after {
        right: 0;
    }



    &--slider &__subheading {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2.8rem;
        line-height: 1.1;
        @media screen and (min-width: 48em) { /* 768px */
            font-size: 4.3rem;
        }
    }

    &--slider &__content {
        align-items: flex-start;
        padding-top: 3rem;
        padding-bottom: 6rem;

    }

    &--slider &__cta {
        min-width: 240px;
        color: $color-white;

        @media screen and (min-width: 48em) { /* 768px */
            padding: $spacing*1.5;
            font-size: 1.8rem;

        }
    }

    &--slider:hover &__cta {
        background: lighten($color-primary, 20%);
        color: $color-secondary;
        transition: all 400ms ease-in-out;
    }

}

.card--simple {
    background: $color-grey-01;

    &:hover {
        box-shadow: none;
    }

    &:focus-within {
        box-shadow: none;
    }

    .card__cta {
        margin-top: $spacing*2;
    }

    .card__content__body {
        flex: 1 0 auto;
    }


}

.card {
    &--product {
        background: $color-grey-01;
        &:hover {
            box-shadow: none;
        }

        &:focus-within {
            box-shadow: none;
        }

    }
    &--product &__img {
        height: auto;

        & img {
            object-fit: auto;
            width: 200px;
            margin: auto;
        }
    }
}
