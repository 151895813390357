
a {
    color: $color-secondary;
    word-break: break-word;
    transition: all $link-transition;
}

a:hover,
a:active,
a:focus {
    color: darken($color-secondary, 20%);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
    outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}

/* Include file type and size in document links */
a.document::after {
    content: " (" attr(data-ext) " " attr(data-size) ")";
}
